import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context";
import { consumeGet } from "../utils/constants";
import Menu from "./Menu";

const Dashboard = () => {
  const { markets, setMarkets } = useContext(AuthContext);
  useEffect(() => {
    const loadMarkets = async () => {
      const result = await consumeGet("markets");
      if (result.status === 200) {
        setMarkets(result.data);
      } else {
        console.log("ERROR", loadMarkets);
      }
    };
    loadMarkets();
  }, []);
  if (markets.length > 0) return <Menu />;
  return <div></div>;
};

export default Dashboard;
