import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Article, Group } from "@mui/icons-material";
import User from "./User";
import Orders from "./Orders";

export default function Selector() {
  const [screen, setScreen] = useState();
  if (!screen) {
    return (
      <Box
        display="flex"
        height="80vh"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button onClick={() => setScreen("ORDERS")} color="inherit">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Article style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>Qualifications</Typography>
          </Box>
        </Button>
        <Button onClick={() => setScreen("USER")} color="inherit">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Group style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>User History</Typography>
          </Box>
        </Button>
      </Box>
    );
  } else {
    if (screen === "USER") return <User handleExit={() => setScreen(null)} />;
    if (screen === "ORDERS")
      return <Orders handleExit={() => setScreen(null)} />;
  }
}
