import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";

import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useParams } from "react-router-dom";
import { serialize } from "object-to-formdata";
import { isArray } from "lodash";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ProductCard = ({ data, handleEdit, handleDelete, turnOffOn, addPosition, loading, theme }) => {
  let newData = [[]];
  let counter = 1;
  let index = 0;
  let firstTime = false;

  const indicatorStyles = {
    background: 'gray',
    width: 12,
    height: 12,
    borderRadius: 20,
    cursor: "pointer",
    display: 'inline-block',
    margin: '0 8px',
  };
  data.forEach((d) => {
    if (counter === 1 && firstTime) {
      newData.push([]);
    } else {
      if (!firstTime) {
        firstTime = true;
      }
    }
    newData[index].push(d);
    counter++;
    if (counter > 11) {
      index++;
      counter = 1;
    }
  });
  return (
    <Carousel
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: theme.palette.primary.main }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
      showArrows={true} showIndicators={true} showThumbs={true} showStatus={false}>
      {newData.map((d, i) => (
        <Grid style={{ paddingBottom: 30 }} container key={i}>
          {d.map((c, index) => (
            <Grid key={index} lg={3} md={4} sm={6} xl={3} xs={12}>
              <Box width={"90%"} style={{ marginBottom: 15 }}>
                <Card style={{ borderRadius: 20, position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={URL + c.image}
                    alt={c.name}
                  />
                  <div style={{ position: "absolute", top: 0, paddingLeft: 10, paddingRight: 10, display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Button disabled={loading} onClick={() => addPosition(c._id, "NEXT")} style={{ fontSize: 28, color: "white", fontWeight: "800" }}>
                      {"<"}
                    </Button>
                    <Button disabled={loading} onClick={() => addPosition(c._id, "BACK")} style={{ fontSize: 28, color: "white", fontWeight: "800" }}>
                      {">"}
                    </Button>
                  </div>
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography>{c.name}</Typography>
                      <Box>
                        <Switch
                          checked={c.status}
                          onChange={() => turnOffOn(c)}
                        />
                        <Button
                          onClick={() => handleEdit(c)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Edit style={{ color: "black" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(c._id)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Delete style={{ color: "black" }} />
                        </Button>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};

export default function Products() {
  const theme = useTheme();
  const { selectedMarket, categories, editCategories, options, setCategories } =
    useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const { category } = useParams();
  const [add, handleAdd] = useState({
    open: false,
    product: {
      name: "",
      image: null,
      description: "",
      instagram: "",
      direction: "",
      phone: "",
      shedule: "",
      email: ""
    },
    edit: false,
  });
  const [deleteModal, handleDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, handleSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);

  const addPosition = async (id, type) => {
    setLoading(true)
    try {
      const catId = selectedCategory._id;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };
      const result = await axios.put(`${URL}order/${id}`, { category: catId, type }, config);
      if (result.status === 200) {
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
      } else {
        console.log(result)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    handleSelectedCategory(categories.find((c) => c._id === category));
  }, [categories, category]);
  useEffect(() => {
    if (selectedCategory) {
      setProducts(selectedCategory.products);
    }
  }, [selectedCategory]);
  if (!selectedCategory)
    return (
      <Box
        width="100%"
        height={400}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AssignmentLate style={{ fontSize: 92 }} />
        <Typography>You have no products created</Typography>
      </Box>
    );
  const turnOffOn = async (product) => {
    try {
      const { _id } = product;
      const result = await axios.put(
        `${URL}statusProduct/${_id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (result.status === 200) {
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
        handleAdd({
          open: false,
          product: {
            name: "",
            email: "",
            image: null,
            description: "",
            instagram: "",
            direction: "",
            phone: "",
            shedule: ""
          },
          edit: false,
        });
      } else {
        setErrors(result.data);
      }
    } catch (err) {
      setErrors(err.response.data.errors);
    }
  };
  const handleConfirm = async () => {
    try {
      setLoading(true);
      const { photo, name, _id, phone, price, description, email, direction, instagram, shedule } =
        add.product;
      const formData = new FormData();
      formData.append("market", selectedMarket._id);
      formData.append("image", photo);
      formData.append("description", description)
      formData.append("email", email)
      formData.append("phone", phone)
      formData.append("direction", direction)
      formData.append("instagram", instagram)
      formData.append("shedule", shedule)
      formData.append("name", name)
      if (!add.edit) {
        formData.append("category", category);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = null;
      if (add.edit) {
        if (photo) {
          result = await axios.put(`${URL}products/${_id}`, formData, config);
        } else {
          result = await axios.put(
            `${URL}products/${_id}`,
            { name, price, description, email, phone, instagram, shedule, direction },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
        }
      } else {
        result = await axios.post(`${URL}products`, formData, config);
      }
      if (result.status === 200) {
        setErrors(null);
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
        handleAdd({
          open: false,
          product: {
            name: "",
            email: "",
            image: null,
            description: "",
            instagram: "",
            direction: "",
            phone: "",
            shedule: ""
          },
          edit: false,
        });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(
      `${URL}products/${category}/${deleteModal}`,
      config
    );
    handleDeleteModal(null);
    if (result.status === 200) {
      editCategories(result.data.category);
    } else {
      console.log("errors");
    }
  };
  return (
    <Box>
      <Dialog fullWidth maxWidth="md" open={add.open}>
        {loading ? (
          <DialogContent>
            <Box
              style={{
                paddingTop: 80,
                paddingBottom: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={70} />
            </Box>
          </DialogContent>
        ) : (
          <Fragment>
            <DialogTitle>Add product</DialogTitle>
            <DialogContent>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Name</Typography>
                  </Box>
                  <Box>
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      inputProps={{ style: { borderRadius: 18 } }}
                      value={add.product.name}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: { ...add.product, name: e.target.value },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Description</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      minRows={3}
                      multiline
                      fullWidth
                      value={add.product.description}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            description: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Email</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      value={add.product.email}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            email: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Phone</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      inputProps={{
                        style: { borderRadius: 18 }
                      }}
                      value={add.product.phone}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            phone: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Localization</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      inputProps={{
                        style: { borderRadius: 18 },
                        maxLength: 100,
                      }}
                      value={add.product.direction}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            direction: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Schedule</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      inputProps={{
                        style: { borderRadius: 18 },
                        maxLength: 100,
                      }}
                      value={add.product.shedule}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            shedule: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Link</Typography>
                  </Box>
                  <Box width="80%">
                    <TextField
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      inputProps={{
                        style: { borderRadius: 18 },
                        maxLength: 100,
                      }}
                      value={add.product.instagram}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            instagram: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Photo</Typography>
                  </Box>
                  <Box>
                    <Button
                      style={{ marginLeft: 24 }}
                      variant="outlined"
                      component="label"
                    >
                      <AttachFile />
                      <input
                        onChange={(e) =>
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              photo: e.target.files[0],
                            },
                          })
                        }
                        type="file"
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
              {errors && (
                <Card style={{ width: "80%", marginLeft: "10%" }}>
                  <CardContent>
                    {Object.values(errors).map((e) => (
                      <Typography color="#df4759" textAlign="center">
                        *{e}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                onClick={() =>
                  handleAdd({
                    open: false,
                    product: {
                      name: "",
                      email: "",
                      image: null,
                      description: "",
                      instagram: "",
                      direction: "",
                      phone: "",
                      shedule: ""
                    },
                    edit: false,
                  })
                }
                variant="contained"
              >
                Cancel
              </Button>
              <Button onClick={() => handleConfirm()} variant="contained">
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
      <Dialog open={deleteModal ? true : false}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Delete product
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              Are you sure? You will lose all information in this product
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => handleDeleteModal(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleErase()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ marginBottom: 12 }}
      >
        <Box display="flex">
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color={theme.palette.primary.main}
              fontWeight={800}
              variant="h2"
              style={{ marginRight: 8 }}
            >
              {"<"}
            </Typography>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color="#5a5959"
              fontWeight={800}
              variant="h2"
            >
              Categories
            </Typography>
          </Link>
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {">"}
          </Typography>
          <Typography
            fontSize={25}
            color="#5a5959"
            fontWeight={800}
            variant="h2"
          >
            {selectedCategory.name}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              boxShadow: "rgb(116 116 116) 1px 2px 4px",
              borderRadius: 12,
            }}
            onClick={() =>
              handleAdd({
                open: true,
                product: {
                  name: "",
                  email: "",
                  image: null,
                  description: "",
                  instagram: "",
                  direction: "",
                  phone: "",
                  shedule: ""
                },
                edit: false,
              })
            }
          >
            <Typography>Add</Typography>
            <Add />
          </Button>
        </Box>
      </Box>
      <Box>
        {products.length > 0 ? (
          <ProductCard
            loading={loading}
            theme={theme}
            data={products.sort((a, b) => a.order > b.order ? 1 : -1)}
            addPosition={addPosition}
            handleEdit={(handler) =>
              handleAdd({
                open: true,
                product: {
                  ...handler,
                },
                edit: true,
              })
            }
            turnOffOn={turnOffOn}
            handleDelete={(handler) => handleDeleteModal(handler)}
          />
        ) : (
          <Box
            width="100%"
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AssignmentLate style={{ fontSize: 92 }} />
            <Typography>You have no products created</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
