import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { serialize } from "object-to-formdata";
import { isArray } from "lodash";

const ProductCard = ({ data, handleEdit, handleDelete }) => {
  let newData = [[]];
  let counter = 1;
  let index = 0;
  let firstTime = false;
  data.forEach((d) => {
    if (counter === 1 && firstTime) {
      newData.push([]);
    } else {
      if (!firstTime) {
        firstTime = true;
      }
    }
    newData[index].push(d);
    counter++;
    if (counter > 9) {
      index++;
      counter = 1;
    }
  });
  return (
    <Carousel showArrows={true} showStatus={false}>
      {newData.map((d, i) => (
        <Grid style={{ paddingBottom: 30 }} container key={i}>
          {d.map((c, index) => (
            <Grid key={index} lg={3} md={4} sm={6} xl={3} xs={12}>
              <Box width={"90%"} style={{ marginBottom: 15 }}>
                <Card style={{ borderRadius: 20 }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={URL + c.image}
                    alt={c.name}
                  />
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography>{c.name}</Typography>
                      <Box>
                        <Button
                          onClick={() => handleDelete(c._id)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Delete style={{ color: "black" }} />
                        </Button>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};

export default function Features() {
  const theme = useTheme();
  const { selectedMarket, features, products, setFeatures, deleteFeatures } =
    useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const selectedFeature = features.find((f) => f.market === selectedMarket._id);
  const [add, handleAdd] = useState({
    open: false,
    feature: null,
    edit: false,
  });
  const [deleteModal, handleDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };
      const data = { product: add.feature, market: selectedMarket._id };
      const result = await axios.post(`${URL}featured`, data, config);
      if (result.status === 200) {
        setErrors(null);
        const AddOrEdit = features.filter((f) => f._id !== result.data._id)
        setFeatures([...AddOrEdit, result.data]);
        handleAdd({
          open: false,
          feature: null,
          edit: false,
        });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.put(
      `${URL}featured/`,
      { product: deleteModal, market: selectedMarket._id },
      config
    );
    handleDeleteModal(null);
    if (result.status === 200) {
      deleteFeatures(result.data);
    } else {
      console.log("errors");
    }
  };
  return (
    <Box>
      <Dialog fullWidth maxWidth="md" open={add.open}>
        <DialogTitle>Add product to feature</DialogTitle>
        <DialogContent>
          <Box
            width="90%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="select">Product To Add</InputLabel>
              <Select
                labelId="select"
                id="demo-simple-select-helper"
                value={add.feature}
                fullWidth
                label="Option List Group"
                onChange={(e) => {
                  handleAdd({
                    ...add,
                    feature: e.target.value,
                  });
                }}
              >
                {products.map((option, io) => (
                  <MenuItem
                    selected={option._id === add.feature}
                    key={io}
                    value={option._id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {errors && (
            <Card style={{ width: "80%", marginLeft: "10%" }}>
              <CardContent>
                {Object.values(errors).map((e) => (
                  <Typography color="#df4759" textAlign="center">
                    *{e}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() =>
              handleAdd({
                open: false,
                product: {
                  name: "",
                  image: null,
                  description: "",
                  price: 0,
                  option_group: [{ name: "", min: 0, max: 0, option: "" }],
                },
                edit: false,
              })
            }
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteModal ? true : false}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Delete product
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              Are you sure? The product will not appear on the features section
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => handleDeleteModal(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleErase()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ marginBottom: 12 }}
      >
        <Box display="flex">
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color={theme.palette.primary.main}
              fontWeight={800}
              variant="h2"
              style={{ marginRight: 8 }}
            >
              {"<"}
            </Typography>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color="#5a5959"
              fontWeight={800}
              variant="h2"
            >
              Categories
            </Typography>
          </Link>
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {">"}
          </Typography>
          <Typography
            fontSize={25}
            color="#5a5959"
            fontWeight={800}
            variant="h2"
          >
            Features
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              boxShadow: "rgb(116 116 116) 1px 2px 4px",
              borderRadius: 12,
            }}
            onClick={() =>
              handleAdd({
                open: true,
                feature: null,
                edit: false,
              })
            }
          >
            <Typography>Add</Typography>
            <Add />
          </Button>
        </Box>
      </Box>
      <Box>
        {selectedFeature && selectedFeature.products.length > 0 ? (
          <ProductCard
            data={selectedFeature.products}
            handleEdit={(handler) =>
              handleAdd({ open: true, product: handler, edit: true })
            }
            handleDelete={(handler) => handleDeleteModal(handler)}
          />
        ) : (
          <Box
            width="100%"
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AssignmentLate style={{ fontSize: 92 }} />
            <Typography>You have no products on features</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
