import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  TableHead,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../../utils/constants";
import { AuthContext } from "../../context";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import { useTheme } from "@emotion/react";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Details from "../orders/Details";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function Orders({ handleExit }) {
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const [selected, setSelected] = useState(null);
  const { selectedMarket, markets } = useContext(AuthContext);
  const [filters, setFilters] = useState({
    qualification: 0,
    customer: "",
    email: "",
    restaurant: "",
    city: null,
    pickup: null,
    filterBy: 0
  });
  const exportData = async () => {
    const result = await axios.get(URL + "exportOrders", {
      params: { ...filters },
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    });
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "orders.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  useEffect(() => {
    const tryFunct = async () => {
      try {
        const result = await consumeGet("orders", filters);

        if (result.status === 200) {
          setOrders(result.data);
        } else {
          console.log(result);
        }
      } catch (err) {
        console.log(err);
      }
    };
    tryFunct();
  }, [selectedMarket]);
  const [modal, setModal] = useState({
    open: false,
    toConfirm: null,
    description: "",
  });
  const data = orders.filter((o) => {
    if (filters.email.trim() !== "") {
      const regex = new RegExp("^" + filters.email, "i");
      if (!regex.test(o.user.email)) {
        return false;
      }
    }
    if (filters.restaurant.trim() !== "") {
      const regex = new RegExp("^" + filters.restaurant, "i");
      if (!regex.test(o.product.name)) {
        return false;
      }
    }
    if (filters.customer.trim() !== "") {
      const regex = new RegExp("^" + filters.customer, "i");
      if (!regex.test(o.user.first_name) && !regex.test(o.user.last_name)) {
        return false;
      }
    }
    if (filters.qualification) {
      if (o.value !== filters.qualification) {
        return false;
      }
    }
    if (filters.city) {
      if (o.market !== filters.city) {
        return false;
      }
    }
    if (filters.pickup) {
      const previus = moment(o.createdAt);
      const actual = filters.pickup;
      if (
        previus.date() !== actual.date() ||
        previus.month() !== actual.month()
      ) {
        return false;
      }
    }
    return true;
  });
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box position="relative">
        <Box>
          {selected && (
            <Details
              order={selected}
              onlySee
              onCancel={() => setSelected(null)}
            />
          )}
          <Box position="absolute" top={-15} left={1}>
            <Button onClick={() => handleExit()}>
              <Typography fontSize={38}>{"<"}</Typography>
            </Button>
          </Box>
          <Typography
            textAlign="center"
            style={{ fontSize: 21, fontWeight: 800, marginBottom: 12 }}
          >
            Qualifications
          </Typography>
          <Box
            marginBottom={3}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box style={{ width: "100%" }} display="flex" justifyContent="center" alignItems="center">
              <Button onClick={exportData} variant="contained">
                Exportar
              </Button>
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography>Customer</Typography>
              <TextField
                value={filters.customer}
                onChange={(e) =>
                  setFilters({ ...filters, customer: e.target.value })
                }
              />
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography>Mail</Typography>
              <TextField
                value={filters.email}
                onChange={(e) => setFilters({ ...filters, email: e.target.value })}
              />
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography>Restaurant</Typography>
              <TextField
                value={filters.restaurant}
                onChange={(e) => setFilters({ ...filters, restaurant: e.target.value })}
              />
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", width: "90%", paddingRight: 20 }}>
              <Typography>Qualification</Typography>
              <Select
                labelId="select"
                id="demo-simple-select-helper"
                value={filters.qualification}
                fullWidth
                label="Option List Group"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    qualification: e.target.value,
                  });
                }}
              >
                <MenuItem
                  selected={0 === filters.qualification}
                  value={0}
                >
                  WithOut
                </MenuItem>
                <MenuItem
                  selected={1 === filters.qualification}
                  value={1}
                >
                  1
                </MenuItem>
                <MenuItem
                  selected={2 === filters.qualification}
                  value={2}
                >
                  2
                </MenuItem>
                <MenuItem
                  selected={3 === filters.qualification}
                  value={3}
                >
                  3
                </MenuItem>
                <MenuItem
                  selected={4 === filters.qualification}
                  value={4}
                >
                  4
                </MenuItem>
                <MenuItem
                  selected={5 === filters.qualification}
                  value={5}
                >
                  5
                </MenuItem>
              </Select>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", width: "90%", paddingRight: 20 }}>
              <Typography>City</Typography>
              <Select
                labelId="select"
                id="demo-simple-select-helper"
                value={filters.city}
                fullWidth
                label="Option List Group"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    city: e.target.value,
                  });
                }}
              >
                <MenuItem
                  selected={filters.city === null}
                  value={null}
                >
                  WithOut
                </MenuItem>
                {markets.map((option, io) => (
                  <MenuItem
                    selected={option._id === filters.city}
                    key={io}
                    value={option._id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box justifyContent="center" display="flex" >
            <Box
              marginBottom={3}
              display="flex"
              width={"25%"}
              alignItems="center"
              justifyContent="space-around"
            >
              <Box style={{ display: "flex", flexDirection: "column", width: "90%", paddingRight: 20 }}>
                <Typography>Order By</Typography>
                <Select
                  labelId="select"
                  id="demo-simple-select-helper"
                  value={filters.filterBy}
                  fullWidth
                  label="Option List Group"
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      filterBy: e.target.value,
                    });
                  }}
                >
                  <MenuItem
                    selected={0 === filters.filterBy}
                    value={0}
                  >
                    Date
                  </MenuItem>
                  <MenuItem
                    selected={1 === filters.qualification}
                    value={1}
                  >
                    Qualification
                  </MenuItem>
                </Select>
              </Box>
            </Box>
            <Box>
              <Typography>Date</Typography>
              <Box display="flex">
                <Box style={{ marginRight: 8 }}>
                  <MobileDatePicker
                    inputFormat="MM/DD/YY"
                    value={filters.pickup}
                    onChange={(newValue) =>
                      setFilters({ ...filters, pickup: newValue })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    onClick={(newValue) =>
                      setFilters({ ...filters, pickup: null })
                    }
                    variant="contained"
                  >
                    X
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          {data.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell>
                    <Typography fontWeight={800}>Customer</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>Qualification</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>Restaurant</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>City</Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  {data.sort((a, b) => {
                    if (filters.filterBy) {
                      if (a.value < b.value) {
                        return 1;
                      }
                      if (a.value > b.value) {
                        return -1;
                      }
                      return 0;
                    } else {
                      if (a.createdAt < b.createdAt) {
                        return 1;
                      }
                      if (a.createdAt > b.createdAt) {
                        return -1;
                      }
                      return 0;
                    }
                  }).map((o) => (
                    <TableRow hover>
                      <TableCell>
                        {o.user.first_name + " " + o.user.last_name}
                      </TableCell>
                      <TableCell>{o.value}</TableCell>
                      <TableCell>{o.product.name}</TableCell>
                      <TableCell>{moment(o.createdAt).format("MM/DD/YYYY")}</TableCell>
                      <TableCell>{markets.find((t) => t._id === o.market).name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              width="100%"
              height={400}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <AssignmentLate style={{ fontSize: 92 }} />
              <Typography>You have no new orders</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
