import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ data, handleEdit, handleDelete }) => {
  let newData = [[]];
  let counter = 1;
  let index = 0;
  const navigate = useNavigate();
  let firstTime = false;
  data.forEach((d) => {
    if (counter === 1 && firstTime) {
      newData.push([]);
    } else {
      if (!firstTime) {
        firstTime = true;
      }
    }
    newData[index].push(d);
    counter++;
    if (counter > 8) {
      index++;
      counter = 1;
    }
  });
  return (
    <Carousel showArrows={true} showStatus={false}>
      {newData.map((d, i) => (
        <Grid justifyContent="center" alignItems="center" style={{ paddingBottom: 30 }} container key={i}>
          {d.map((c, index) => (
            <Grid key={index} lg={3} md={4} sm={6} xl={3} xs={12}>
              <Box width={"250px"} style={{ marginBottom: 15 }}>
                <Card style={{ borderRadius: 20 }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={URL + c.image}
                    alt={c.name}
                    itemProp={{
                      style: { cursor: "pointer", "pointer-events": "all" },
                    }}
                    style={{ cursor: "pointer", "pointer-events": "all" }}
                    onClick={() => navigate(`/categories/${c._id}`)}
                  />
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography fontWeight={800}>{c.name}</Typography>
                      <Box>
                        <Button
                          onClick={() =>
                            handleEdit({ name: c.name, id: c._id })
                          }
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Edit style={{ color: "black" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(c._id)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Delete style={{ color: "black" }} />
                        </Button>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};

export default function Categories() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const {
    categories,
    setCategories,
    editCategories,
    deleteCategories,
    selectedMarket,
  } = useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const [add, handleAdd] = useState({
    open: false,
    category: {},
    edit: false,
  });
  const [deleteModal, handleDeleteModal] = useState(null);
  const handleConfirm = async () => {
    try {
      setLoading(true);
      const { photo, name, id } = add.category;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("market", selectedMarket._id);
      if (photo) {
        formData.append("image", photo);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = null;
      if (add.edit) {
        if (photo) {
          result = await axios.put(`${URL}categories/${id}`, formData, config);
        } else {
          result = await axios.put(
            `${URL}categories/${id}`,
            { name: name, market: selectedMarket },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
        }
      } else {
        result = await axios.post(`${URL}categories`, formData, config);
      }
      if (result.status === 200) {
        setErrors(null);
        if (add.edit) {
          editCategories(result.data);
        } else {
          setCategories([...categories, result.data]);
        }
        handleAdd({ open: false, category: {} });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(
      `${URL}categories/${deleteModal}`,
      config
    );
    handleDeleteModal(null);
    if (result.status === 200) {
      deleteCategories(result.data);
    } else {
      console.log("errors");
    }
  };
  return (
    <Box>
      <Dialog fullWidth maxWidth="md" open={add.open}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <FormGroup aria-label="position" style={{ marginBottom: 20 }} row>
            <FormControlLabel
              control={
                <TextField
                  style={{
                    marginLeft: 24,
                    boxShadow: "rgb(116 116 116) 1px 0px 1px",
                    borderRadius: 4,
                  }}
                  inputProps={{ style: { borderRadius: 18 } }}
                  value={add.category.name}
                  onChange={(e) =>
                    handleAdd({
                      ...add,
                      category: { ...add.category, name: e.target.value },
                    })
                  }
                />
              }
              label="Name"
              labelPlacement="start"
            />
          </FormGroup>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Button
                  style={{ marginLeft: 24 }}
                  variant="outlined"
                  component="label"
                >
                  <AttachFile />
                  <input
                    onChange={(e) =>
                      handleAdd({
                        ...add,
                        category: { ...add.category, photo: e.target.files[0] },
                      })
                    }
                    type="file"
                    hidden
                  />
                </Button>
              }
              label="Photo"
              labelPlacement="start"
            />
          </FormGroup>
          {errors && (
            <Card style={{ width: "80%", marginLeft: "10%" }}>
              <CardContent>
                {Object.values(errors).map((e) => (
                  <Typography color="#df4759" textAlign="center">
                    *{e}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() =>
              handleAdd({ open: false, edit: false, category: {} })
            }
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleConfirm()}
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteModal}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Delete Category
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              Are you sure? You will lose all information in this category
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => handleDeleteModal(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleErase()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ marginBottom: 12 }}
      >
        <Box display="flex">
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ marginRight: 8 }}
          >
            {"<"}
          </Typography>
          <Typography
            fontSize={25}
            color="#5a5959"
            fontWeight={800}
            variant="h2"
          >
            Categories
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              boxShadow: "rgb(116 116 116) 1px 2px 4px",
              borderRadius: 12,
            }}
            onClick={() => handleAdd({ open: true, edit: false, category: {} })}
          >
            <Typography>Add</Typography>
            <Add />
          </Button>
        </Box>
      </Box>
      <Box>
        {categories.length > 0 ? (
          <CategoryCard
            data={categories}
            handleEdit={(handler) =>
              handleAdd({ open: true, category: handler, edit: true })
            }
            handleDelete={(handler) => handleDeleteModal(handler)}
          />
        ) : (
          <Box
            width="100%"
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AssignmentLate style={{ fontSize: 92 }} />
            <Typography>You have no categories created</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Grid justifyContent="center" alignItems="center" container>
          <Grid md={2} sm={4}>
            <Typography
              textAlign="center"
              fontSize={28}
              style={{ marginTop: 55 }}
            >
              Favorites
            </Typography>
          </Grid>
          <Grid md={4} sm={8}>
            <Box width={"90%"} style={{ marginBottom: 15 }}>
              <Link to="/features" style={{ textDecoration: "none" }}>
                <Card style={{ borderRadius: 20 }}>
                  <CardMedia component="img" height="150" image={Option} />
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography>Features</Typography>
                    </Box>
                  </CardActions>
                </Card>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
