import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  TableHead,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../../utils/constants";
import { AuthContext } from "../../context";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import { useTheme } from "@emotion/react";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
export default function User({ handleExit }) {
  const theme = useTheme();
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState({ mail: "", customer: "" });
  const [users, setUsers] = useState([]);
  const { selectedMarket } = useContext(AuthContext);
  useEffect(() => {
    const tryFunct = async () => {
      try {
        const result = await consumeGet("users", {});

        if (result.status === 200) {
          setUsers(result.data);
        } else {
          console.log(result);
        }
      } catch (err) {
        console.log(err);
      }
    };
    tryFunct();
  }, [selectedMarket]);
  const [modal, setModal] = useState({
    open: false,
    toConfirm: null,
    description: "",
  });
  const exportData = async () => {
    const result = await axios.get(URL + "exportUsers", {
      params: filters,
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    });
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "users.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const data = users.filter((o) => {
    if (filters.mail.trim() !== "") {
      const regex = new RegExp("^" + filters.mail, "i");
      if (!regex.test(o.email)) {
        return false;
      }
    }
    if (filters.customer.trim() !== "") {
      const regex = new RegExp("^" + filters.customer, "i");
      if (!regex.test(o.first_name)) {
        return false;
      }
    }
    return true;
  });
  return (
    <Box position="relative">
      <Box>
        <Box position="absolute" top={-15} left={1}>
          <Button onClick={() => handleExit()}>
            <Typography fontSize={38}>{"<"}</Typography>
          </Button>
        </Box>
        <Typography
          textAlign="center"
          style={{ fontSize: 21, fontWeight: 800, marginBottom: 12 }}
        >
          User History
        </Typography>
        <Box
          marginBottom={3}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button onClick={exportData} variant="contained">
              Exportar
            </Button>
          </Box>
          <Box>
            <Typography>Customer</Typography>
            <TextField
              value={filters.customer}
              onChange={(e) =>
                setFilters({ ...filters, customer: e.target.value })
              }
            />
          </Box>
          <Box>
            <Typography>Mail</Typography>
            <TextField
              value={filters.mail}
              onChange={(e) => setFilters({ ...filters, mail: e.target.value })}
            />
          </Box>
        </Box>
        {data.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell>
                  <Typography fontWeight={800}>Customer</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={800}>Phone</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={800}>Mail</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={800}>Registration Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={800}>Verified</Typography>
                </TableCell>
              </TableHead>
              <TableBody>
                {data.map((o) => (
                  <TableRow hover>
                    <TableCell>{o.first_name + " " + o.last_name}</TableCell>
                    <TableCell>{o.telefono}</TableCell>
                    <TableCell>{o.email}</TableCell>
                    <TableCell>
                      {moment(o.createdAt).format("MM/DD/YY")}
                    </TableCell>
                    <TableCell>
                      {o.verified ? "ACTIVE" : "INACTIVE"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            width="100%"
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AssignmentLate style={{ fontSize: 92 }} />
            <Typography>You have no new orders</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
