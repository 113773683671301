import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  Divider,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../../utils/constants";
import { AuthContext } from "../../context";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  KeyboardArrowUp,
  Person,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
export default function Details(props) {
  const { codes } = useContext(AuthContext);
  const theme = useTheme();
  const [time, setTime] = useState(props.order.pickTime);
  const [show, setShow] = useState(null);
  const { order, handleConfirm } = props;
  let ourCode;
  if (order.userCode && order.usedCode.trim() !== "") {
    ourCode = codes.find((v) => v.code === order.usedCode);
  }
  return (
    <Dialog fullWidth maxWidth="lg" open={true}>
      <Button
        onClick={props.onCancel}
        variant="contained"
        style={{
          position: "absolute",
          top: 5,
          right: 5,
          height: 40,
          width: 40,
          borderRadius: 40,
        }}
      >
        <Typography fontSize={20}>X</Typography>
      </Button>
      <DialogContent>
        <Box paddingLeft={5} paddingRight={5}>
          <Box justifyContent="space-between" marginTop={3} display="flex">
            <Box alignItems="center" display="flex">
              <Typography fontWeight={800}>Pick up in</Typography>
              <Box
                style={{
                  backgroundColor: theme.palette.primary.main,
                  paddingRight: 20,
                  paddingLeft: 20,
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: 8,
                  marginRight: 8,
                  marginLeft: 12,
                }}
              >
                <Typography style={{ color: "white", textAlign: "center" }}>
                  {time}
                </Typography>
                <Typography style={{ color: "white", textAlign: "center" }}>
                  Min
                </Typography>
              </Box>
              {props.canEdit && (
                <Box display="flex" flexDirection="column">
                  <Button
                    variant="contained"
                    style={{
                      height: 28,
                      marginBottom: 2,
                      width: 30,
                      minWidth: 30,
                      padding: 0,
                    }}
                    onClick={() => {
                      if (time < 60) {
                        setTime(time + 5);
                      }
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      <ArrowUpward />
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (time > 5) {
                        setTime(time - 5);
                      }
                    }}
                    variant="contained"
                    style={{ height: 28, width: 30, minWidth: 30, padding: 0 }}
                  >
                    <Typography style={{ color: "white" }}>
                      <ArrowDownward />
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
            <Box display="flex">
              <Person color="primary" style={{ fontSize: 92 }} />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                <Typography>
                  {order.user.first_name} {order.user.last_name}
                </Typography>
                <Typography>{order.user.telefono}</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" paddingLeft={2} justifyContent="space-between">
              <Typography fontWeight={800}>
                {order.products.length} items
              </Typography>
              <Typography fontWeight={800}>Order N° {order.orderId}</Typography>
            </Box>
            <Divider style={{ borderColor: "#000" }} />
            <Box>
              {order.products.map((p, i) => {
                let totalSubPrice = p.product.price;
                p.selected.forEach((o) =>
                  o.options.forEach(
                    (f) => (totalSubPrice += o.option_group.options[f].price)
                  )
                );
                totalSubPrice = totalSubPrice * p.quantity;
                return (
                  <Box style={{ backgroundColor: "rgba(242, 242, 242, 0.60)" }}>
                    <Box
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography fontWeight={800}>
                        {p.quantity} X {p.product.name}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Typography fontWeight={800}>
                          $ {totalSubPrice.toFixed(2)}
                        </Typography>
                        <Button
                          color="inherit"
                          onClick={() => setShow(i !== show ? i : null)}
                          style={{ minWidth: 20 }}
                        >
                          {p.selected.length > 0 ? (
                            show === i ? (
                              <KeyboardArrowUp style={{ fontSize: 35 }} />
                            ) : (
                              <KeyboardArrowDownIcon style={{ fontSize: 35 }} />
                            )
                          ) : null}
                        </Button>
                      </Box>
                    </Box>
                    {show === i &&
                      p.selected.map((s) => (
                        <Box
                          style={{
                            backgroundColor: "white",
                            marginLeft: 40,
                            borderRadius: 8,
                          }}
                          marginRight={8}
                          paddingRight={1}
                        >
                          <Box
                            style={{
                              paddingLeft: 18,
                              marginBottom: 12,
                              borderLeft: "rgb(78 77 77) solid 3px",
                            }}
                          >
                            <Typography style={{ color: "rgb(169 163 163)" }}>
                              {s.option_group.name}
                            </Typography>
                            {s.options.map((t) => (
                              <Box
                                justifyContent="space-between"
                                display="flex"
                              >
                                <Typography fontWeight={800}>
                                  {s.option_group.options[t].name}
                                </Typography>
                                <Typography fontWeight={800}>
                                  {s.option_group.options[t].price
                                    ? "$" +
                                    s.option_group.options[t].price.toFixed(2)
                                    : "--"}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                );
              })}
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {
                  !ourCode ? (
                    <Fragment>

                      <Box style={{ marginRight: 28 }}>
                        <Typography fontWeight={800}>SubTotal</Typography>
                        <Typography fontWeight={800}>Tax 7%</Typography>
                        <Typography fontWeight={800}>Tip</Typography>
                        <Typography fontWeight={800}>Total</Typography>
                      </Box>
                      <Box>
                        <Typography fontWeight={800}>
                          {order.total.toFixed(2)}
                        </Typography>
                        <Typography fontWeight={800}>{(order.total * 0.07).toFixed(2)}</Typography>
                        <Typography fontWeight={800}>{order.tip.toFixed(2)}</Typography>
                        <Typography fontWeight={800}>
                          {(order.total + (order.total * 0.07) + order.tip).toFixed(2)}
                        </Typography>
                      </Box>
                    </Fragment>
                  ) : (
                    <Fragment>

                      <Box style={{ marginRight: 28 }}>
                        <Typography fontWeight={800}>SubTotal</Typography>
                        <Typography fontWeight={800}>Discount</Typography>
                        <Typography fontWeight={800}>Code</Typography>
                        <Divider style={{ borderColor: "#000" }} />
                        <Typography fontWeight={800}>SubTotal</Typography>
                        <Typography fontWeight={800}>Tax 7%</Typography>
                        <Typography fontWeight={800}>Tip</Typography>
                        <Divider style={{ borderColor: "#000" }} />
                        <Typography fontWeight={800}>Total</Typography>
                      </Box>
                      <Box>
                        <Typography fontWeight={800}>
                          {order.total.toFixed(2)}
                        </Typography>
                        <Typography fontWeight={800}>
                          {(order.total * parseFloat(`0.${[ourCode.discount]}`)).toFixed(2)}
                        </Typography>
                        <Typography fontWeight={800}>
                          {ourCode.codeName}
                        </Typography>
                        <Divider style={{ borderColor: "#000" }} />
                        <Typography fontWeight={800}>
                          {(order.total - (order.total * parseFloat(`0.${[ourCode.discount]}`))).toFixed(2)}
                        </Typography>
                        <Typography fontWeight={800}>{(order.total * 0.07).toFixed(2)}</Typography>
                        <Typography fontWeight={800}>{order.tip.toFixed(2)}</Typography>
                        <Divider style={{ borderColor: "#000" }} />
                        <Typography fontWeight={800}>
                          {(order.total + (order.total * 0.07) + order.tip - (order.total * parseFloat(`0.${[ourCode.discount]}`))).toFixed(2)}
                        </Typography>
                      </Box>
                    </Fragment>

                  )
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {order.pickup === "PENDING" && (
          <Fragment>
            <Button
              onClick={() => handleConfirm("READY", time)}
              style={{
                backgroundColor: "rgba(14, 220, 47, 1)",
                color: "white",
                width: "170px",
              }}
              color="inherit"
              variant="contained"
            >
              Ready for Pick Up
            </Button>
            <Button
              onClick={() => handleConfirm("PROCESSED", time)}
              style={{ width: "170px" }}
              variant="contained"
            >
              Confirm
            </Button>
          </Fragment>
        )}
        {order.pickup === "PROCESSED" && (
          <Fragment>
            <Button
              onClick={() => handleConfirm("READY", time)}
              style={{
                backgroundColor: "rgba(14, 220, 47, 1)",
                color: "white",
                width: "170px",
              }}
              color="inherit"
              variant="contained"
            >
              Ready for Pick Up
            </Button>
            <Button
              onClick={props.onCancel}
              style={{ width: "170px" }}
              variant="contained"
            >
              Ok
            </Button>
          </Fragment>
        )}
        {order.pickup === "READY" && (
          <Button
            onClick={props.onCancel}
            style={{ width: "170px" }}
            variant="contained"
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
